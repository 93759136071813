import React from "react"
import { Col, Row } from "antd"

const CreditCardPreview = ({ data }) => {
  const {
    cardType,
    expirationMonth,
    expirationYear,
    expired,
    imageUrl,
    last4,
  } = data
  return (
    <div className={"credit-card-preview"}>
      <Row justify="space-between">
        <Col>
          <img src={imageUrl} alt={cardType} /> {cardType} XXXX-{last4}
        </Col>
        <Col>
          <div className={`card-expiration-date ${expired ? "expired" : ""}`}>
            {expirationMonth}/{expirationYear}
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default CreditCardPreview
