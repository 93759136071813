import React from "react"
import { Col, Row } from "antd"

const PaypalPreview = ({ data }) => {
  const { cardType, email, imageUrl } = data
  return (
    <div className={"credit-card-preview"}>
      <Row justify="space-between">
        <Col>
          <img src={imageUrl} alt={cardType} /> {cardType}{" "}
          {email.replace(/(.{2})(.*)(?=@)/, function (gp1, gp2, gp3) {
            for (let i = 0; i < gp3.length; i++) {
              gp2 += "*"
            }
            return gp2
          })}
        </Col>
        <Col></Col>
      </Row>
    </div>
  )
}

export default PaypalPreview
