import React, { useEffect, useState } from "react"
import PageRoot from "../../../../components/PageRoot"
import LoadingScreen from "../../../../components/LoadingScreen"
import ErrorScreen from "../../../../components/ErrorScreen"
import { gql, useQuery } from "@apollo/client"
import { Alert, Button, Card, Col, Empty, List, Popconfirm, Row } from "antd"
import CreditCardPreview from "../../../../components/CreditCardPreview"
import ContainerWithPadding from "../../../../components/ContainerWithPadding"
import { Link } from "gatsby"
import { devLog, translatePath, uiHandleError } from "../../../../utils"
import PaypalPreview from "../../../../components/PaypalPreview"
import { useTranslation } from "react-i18next"
import useBraintree from "../../../../hooks/useBraintree"

const GET_USER_BILLING_PROFILES_QUERY = gql`
  query billingProfile {
    billing_profiles {
      id
      lastname
      firstname
      billing_address
      business_address
      email
      phone_number
      tax_name
      tax_code
      vat_number
    }
  }
`

const contentColSettings = {
  xs: 24,
  sm: 24,
  md: 24,
  lg: 18,
  xl: 16,
  xxl: 12,
}

const PaymentMethodPreview = ({ id }) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(true)
  const [paymentMethod, setPaymentMethod] = useState(null)
  const [error, setError] = useState(null)
  const { getUserPaymentMethod } = useBraintree()
  const loadData = async () => {
    try {
      const data = await getUserPaymentMethod({ billingProfileId: id })
      devLog({ paymentMethodData: data })
      setPaymentMethod(data)
    } catch (error) {
      setError(error)
    }
    setLoading(false)
  }
  useEffect(() => {
    loadData()
  }, [])

  if (loading) {
    return <LoadingScreen />
  }

  if (error) {
    return <ErrorScreen error={error} layout="compact" />
  }

  return paymentMethod.bin ? (
    <CreditCardPreview data={paymentMethod} />
  ) : (
    <PaypalPreview data={paymentMethod} />
  )
}

const UserBillingProfileList = () => {
  const { t } = useTranslation()
  const pageTitle = t("label:paymentMethods")

  const { loading, error, data, refetch } = useQuery(
    GET_USER_BILLING_PROFILES_QUERY,
    { fetchPolicy: "network-only" }
  )
  const { deleteUserPaymentMethod } = useBraintree()

  if (loading) {
    return (
      <PageRoot title={pageTitle} showTitle>
        <LoadingScreen />
      </PageRoot>
    )
  }

  if (error) {
    return (
      <PageRoot title={pageTitle} showTitle>
        <ErrorScreen error={error} />
      </PageRoot>
    )
  }

  const removePaymentMethod = async billingProfileId => {
    // if (data.billing_profiles.length === 1) {
    //   return uiHandleError({
    //     message: t("error:atLeastOnePaymentMethod"),
    //   })
    // }

    try {
      await deleteUserPaymentMethod(billingProfileId)
      await refetch()
    } catch (err) {
      uiHandleError({ error: err })
    }
  }

  return (
    <PageRoot title={pageTitle} showTitle>
      <ContainerWithPadding>
        <Row justify={"center"}>
          <Col {...contentColSettings}>
            <Row justify="space-between">
              <Col>
                <h2>{t("message:myPaymentMethods")}</h2>
                <p>{t("message:myPaymentMethodsMessage1")}</p>
              </Col>
              <Col>
                <Button type="primary" className="green">
                  <Link to={translatePath(`/me/billing/payment-method/add`)}>
                    {t("button:add")}
                  </Link>
                </Button>
              </Col>
            </Row>
            <Alert
              type="warning"
              showIcon
              message={
                <p style={{ margin: 0 }}>
                  {t("warning:noChargeAppliesWhenAddPaymentMethod1")}
                  <br />
                  {t("warning:noChargeAppliesWhenAddPaymentMethod2")}
                </p>
              }
            />
            <hr />
            {data.billing_profiles.length > 0 ? (
              <List
                dataSource={data.billing_profiles}
                renderItem={item => {
                  const {
                    firstname,
                    lastname,
                    vat_number,
                    tax_name,
                    tax_code,
                    email,
                    phone_number,
                    billing_address,
                    business_address,
                  } = item

                  const address = business_address || billing_address

                  return (
                    <List.Item
                      key={item.id}
                      actions={[
                        <Button className="hover-yellow">
                          <Link
                            to={`/me/billing/payment-method/edit?id=${item.id}`}
                          >
                            {t("button:edit")}
                          </Link>
                        </Button>,
                        <Popconfirm
                          placement="top"
                          title={t("message:removePaymentMethodConfirmation")}
                          onConfirm={() => {
                            removePaymentMethod(item.id)
                          }}
                          okText={t("button:remove")}
                          cancelText={t("button:cancel")}
                        >
                          <Button type="danger">{t("button:remove")}</Button>
                        </Popconfirm>,
                      ]}
                    >
                      <Card title={null} style={{ width: "100%" }}>
                        {(firstname || lastname) && (
                          <h3>{`${firstname} ${lastname}`}</h3>
                        )}
                        {tax_code && <h3>{tax_code}</h3>}
                        <h3>{email}</h3>
                        <h3>{phone_number}</h3>
                        {(vat_number || tax_name) && (
                          <h3>{`${tax_name} - ${vat_number}`}</h3>
                        )}
                        {address && (
                          <p>
                            {address.address_line1}
                            <br />
                            {address.city} - {address.postal_code}
                            <br />
                          </p>
                        )}
                        <PaymentMethodPreview id={item.id} />
                      </Card>
                    </List.Item>
                  )
                }}
              />
            ) : (
              <Empty description={t("message:noPaymentMethodsAvailable")} />
            )}
          </Col>
        </Row>
      </ContainerWithPadding>
    </PageRoot>
  )
}

export default UserBillingProfileList
